import styled from "styled-components";
import { AppHelmet } from "./AppHelmet";
import { Footer } from "./Footer";
import { Header } from "./Header/Header";
import { useEffect } from "react";

const PageContainer = styled.div`
  width: 100%;
`;

export const Page = ({ children, title, description }) => {
  useEffect(() => {
    window?.scrollTo(0, 0);
  }, []);

  return (
    <PageContainer>
      <AppHelmet title={title} description={description} />
      <Header />
      {children}
      <Footer />
    </PageContainer>
  );
};

import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { Languages } from "../Languages";
import { NavBarLink } from "../NavbarLink";
import { useNavLinks } from "../useNavLinks";
import { MobileMenuDropdown } from "./MobileMenuDropdown";

const MenuStyled = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  background-color: #fff;
  z-index: 999;
  width: 100%;
  height: 100%;
`;

const CloseIconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
`;

const MenuBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px 20px;
  overflow-y: auto;

`;

const NavLinkWrapper = styled.div`
  margin-bottom: 10px;
`;

const LanguagesWrapper = styled.div`
  margin-top: 35px;
`;

export const Menu = ({ setOpen }) => {
  const navLinks = useNavLinks();
  return (
    <MenuStyled>
      <CloseIconWrapper>
        <CloseIcon icon={faClose} size={"2x"} onClick={() => setOpen(false)} />
      </CloseIconWrapper>
      <MenuBody>
        {navLinks.map((navLink) => (
          <NavLinkWrapper key={navLink.label}>
            {navLink.link && (
              <NavBarLink link={navLink.link} label={navLink.label} />
            )}
            {navLink.subItems && (
              <MobileMenuDropdown
                label={navLink.label}
                items={navLink.subItems}
              />
            )}
          </NavLinkWrapper>
        ))}
        <LanguagesWrapper>
          <Languages />
        </LanguagesWrapper>
      </MenuBody>
    </MenuStyled>
  );
};

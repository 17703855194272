import { useTranslation } from "react-i18next";
import { SelectDropdown } from "../Select";

const options = [
  {
    label: "CHF 300",
    value: "CHF 300",
  },
  {
    label: "CHF 500",
    value: "CHF 500",
  },
  {
    label: "CHF 1'000",
    value: "CHF 1'000",
  },
  {
    label: "CHF 1'500",
    value: "CHF 1'500",
  },
  {
    label: "CHF 2'000",
    value: "CHF 2'000",
  },
  {
    label: "CHF 2'500",
    value: "CHF 2'500",
  },
];

export const DesiredFranchiseSelect = ({
  isLight,
  name,
  value,
  onChange,
}) => {
  const { t } = useTranslation();
  return (
    <SelectDropdown
      options={options}
      label={t("compareForm.desiredFranchise")}
      placeholder={t("compareForm.desiredFranchise")}
      isLight={isLight}
      name={name}
      value={value}
      onChange={onChange}
    />
  );
};

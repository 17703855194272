import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Input } from "./Input";
import { createGlobalStyle } from "styled-components";
import { forwardRef } from "react";

const DatePickerWrapperStyles = createGlobalStyle`
     .react-datepicker {
        font-family: Open sans;
    };
    .react-datepicker__triangle{
        display:none;
    };
    .react-datepicker__header {
        background-color: ${({ theme }) => theme.colors.bgColorLight};
    };
    .react-datepicker__navigation-icon {
        ::before{
         border-color: ${({ theme }) => theme.colors.text};
        };
    };
    .react-datepicker__current-month {
         color:${({ theme }) => theme.colors.text};
         margin-bottom: 8px;
         font-size: 16px;
    };
    .react-datepicker__header__dropdown {
         display:flex;
         justify-content: space-between;
         padding: 0 5px 0 0;
    };
    .react-datepicker__month-read-view--down-arrow, .react-datepicker__year-read-view--down-arrow{
         border-color: ${({ theme }) => theme.colors.text};
         border-width: 2px 2px 0 0;
         right: -17px;
         top: 4px;
         width: 7px;
         height: 7px;
    };
    .react-datepicker__month-read-view--selected-month, .react-datepicker__year-read-view--selected-year{
        color: ${({ theme }) => theme.colors.text};
        font-size: 14px;
    };
    .react-datepicker__month-dropdown{
        left: 0%;
        background-color: #FFF;
        border-color: ${({ theme }) => theme.colors.grey};
    };
    .react-datepicker__year-dropdown{
        left:50%;
        background-color: #FFF;
        border-color: ${({ theme }) => theme.colors.grey};
    };
    .react-datepicker__month-option, .react-datepicker__year-option{
        color:${({ theme }) => theme.colors.text};
        :hover{
            background-color: ${({ theme }) => theme.colors.bgColorLight};
        }
    };
    .react-datepicker__year-dropdown--scrollable{
        ::-webkit-scrollbar {
            width: 6px;
        }

        ::-webkit-scrollbar-track {
            background: ${({ theme }) => theme.colors.greyLight};
        }

        ::-webkit-scrollbar-thumb {
            background: ${({ theme }) => theme.colors.primaryLight};
        }

        ::-webkit-scrollbar-thumb:hover {
            background: ${({ theme }) => theme.colors.primaryLight};
        }
    };
    .react-datepicker__day--keyboard-selected{
        background-color: ${({ theme }) => theme.colors.bgColorLight};
    }
`;

export const DatePickerInput = ({
  label,
  placeholder,
  isLight,
  value,
  setFieldValue,
  name,
}) => {
  const CustomInput = forwardRef(({ value, onClick, onChange, name }, ref) => {
    return (
      <div onClick={onClick} ref={ref}>
        <Input
          value={value}
          label={label}
          isLight={isLight}
          placeholder={placeholder}
          name={name}
          onChange={onChange}
        />
      </div>
    );
  });

  return (
    <>
      <ReactDatePicker
        placeholderText={placeholder}
        customInput={<CustomInput />}
        showYearDropdown
        yearDropdownItemNumber={100}
        scrollableYearDropdown
        showMonthDropdown
        value={value}
        name={name}
        onChange={(val) => setFieldValue(name, val)}
        selected={value}
      />
      <DatePickerWrapperStyles />
    </>
  );
};

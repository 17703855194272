import { Helmet } from "react-helmet-async";

export const AppHelmet = ({ children, title, description }) => (
  <>
    <Helmet>
      <title>{title}</title>

      <meta name="description" content={description} />
    </Helmet>
  </>
);

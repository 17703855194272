export const ROUTES = {
  INSURANCE_COMPARISON: "/versicherung-vergleich",
  ABOUT_US: "/uber-uns",
  FRANCHISE_DEDUCTIBLE: "/franchise-und-selbstbehalt",
  FAMILY_DISCOUNTS: "/familienrabatte",
  INSURANCE_MODEL: "/versicherungsmodell",
  MATERNITY_PRENATAL: "/mutterschaft-und-vorgeburt",
  RESERVES: "/reserven-ausschuttung",
  GUEST_INSURANCE: "/gasteversicherung",
  TRAVEL_INSURANCE: "/reiseversicherung",
  PREMIUM_REDUCTION: "/pramienverbilligung",
  DENTAL_INSURANCE: "/zahnversicherung",
  HOSPITAL_INSURANCE: "/spitalzusatzversicherungen",
  DEATH_INSURANCE: "/karenzfrist-in-der-invaliden-und-tod-versicherung",
  BASIC_ADDITIONAL_INSURANCE: "/grundversicherung-und-zusatzversicherung",
  IMPRESSUM: "/impressum",
};

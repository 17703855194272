import styled from "styled-components";

const LabelStyled = styled.div`
  font-size: 16px;
  color: ${({ theme, isLight }) => (isLight ? "#FFF" : theme.colors.text)};
  margin-bottom: 5px;
  font-weight: 500;
`;

export const Label = ({ label, isLight }) => {
  return <LabelStyled isLight={isLight}>{label}</LabelStyled>;
};

import { BrowserRouter } from "react-router-dom";
import { Router } from "./pages/Router";
import { ThemeProvider } from "styled-components";
import theme from "./theme/theme";
import { CurrentInsuranceProvider } from "./providers/CurrentInsuranceProvider";
import ReactGA from "react-ga4";

import { HelmetProvider } from "react-helmet-async";

ReactGA.initialize("G-1ZZX2Y24KT");

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CurrentInsuranceProvider>
          <HelmetProvider>
            <Router />
          </HelmetProvider>
        </CurrentInsuranceProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;

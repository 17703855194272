import { Input } from "../Input";
import * as Yup from "yup";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { DatePickerInput } from "../DatePickerInput";
import { AgreementCheckbox } from "./AgreementCheckbox";
import { Button } from "../Button";
import { CurrentInsuranceSelect } from "../CurrentInsuranceSelect/CurrentInsuranceSelect";
import { DesiredFranchiseSelect } from "./DesiredFranchiseSelect";
import { DoctorModelSelect } from "./DoctorModelSelect";
import { Form, Formik } from "formik";
import emailjs from "@emailjs/browser";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { useCurrentInsurance } from "../../providers/CurrentInsuranceProvider";
import { useCallback } from "react";

const FormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media all and (max-width: 780px) {
    flex-direction: column;
  }
`;

const InputWrapper = styled.div`
  width: 48%;
  margin-bottom: 20px;
  @media all and (max-width: 780px) {
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;
`;

export const CompareForm = ({
  isLight,
  onCloseModal,
  setShowSuccessMessage,
}) => {
  const { t } = useTranslation();
  const { setCurrentInsurance } = useCurrentInsurance();

  const initialValues = {
    currentInsurance: null,
    name: "",
    desiredFranchise: null,
    postalCode: "",
    birthDate: "",
    doctorModel: null,
    phoneNumber: "",
    email: "",
    isAgreed: false,
  };

  const validationSchema = () =>
    Yup.object().shape({
      email: Yup.string()
        .required(t("formErrors.required"))
        .email(t("formErrors.email")),
      name: Yup.string().required(t("formErrors.required")),
      desiredFranchise: Yup.object().required(t("formErrors.required")),
      postalCode: Yup.number().required(t("formErrors.required")),
      birthDate: Yup.string().required(t("formErrors.required")),
      doctorModel: Yup.object().required(t("formErrors.required")),
      phoneNumber: Yup.string().required(t("formErrors.required")),
      currentInsurance: Yup.object().required(t("formErrors.required")),
      isAgreed: Yup.bool().oneOf([true], "Field must be checked"),
    });

  const handleResetForm = useCallback(
    (resetForm) => {
      resetForm();
      setCurrentInsurance(null);
    },
    [setCurrentInsurance]
  );

  const handleSubmit = (values, { resetForm }) => {
    const emailValues = {
      ...values,
      desiredFranchise: values.desiredFranchise?.label,
      doctorModel: values.doctorModel.label,
      currentInsurance: values.currentInsurance.label,
      birthDate: format(new Date(values.birthDate), "/dd/MM/yyyy"),
    };

    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        emailValues,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          handleResetForm(resetForm);
          setShowSuccessMessage(true);
        },
        (err) => {
          console.log("FAILED...", err);
          toast.error("Something went wrong");
          if (onCloseModal) {
            onCloseModal();
          }
        }
      );
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ values, errors, handleChange, setFieldValue, dirty, isValid }) => {
        const getErrorMessage = (errorMsg) => {
          if (errorMsg === t("formErrors.required")) return "";

          return errorMsg;
        };

        return (
          <Form>
            <FormContainer>
              <InputWrapper>
                <CurrentInsuranceSelect
                  isLight={isLight}
                  hasLabel
                  name="currentInsurance"
                  value={values.currentInsurance}
                  onChange={(newVal) =>
                    setFieldValue("currentInsurance", newVal)
                  }
                />
              </InputWrapper>
              <InputWrapper>
                <Input
                  label={t("compareForm.name")}
                  placeholder={t("compareForm.name")}
                  isLight={isLight}
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                  error={getErrorMessage(errors.name)}
                />
              </InputWrapper>
              <InputWrapper>
                <DesiredFranchiseSelect
                  isLight={isLight}
                  name="desiredFranchise"
                  value={values.desiredFranchise}
                  onChange={(newVal) =>
                    setFieldValue("desiredFranchise", newVal)
                  }
                />
              </InputWrapper>
              <InputWrapper>
                <Input
                  label={t("compareForm.postal")}
                  placeholder={t("compareForm.postal")}
                  isLight={isLight}
                  name="postalCode"
                  onChange={handleChange}
                  value={values.postalCode}
                  error={getErrorMessage(errors.postalCode)}
                  type="number"
                />
              </InputWrapper>
              <InputWrapper>
                <DatePickerInput
                  label={t("compareForm.birthDate")}
                  placeholder={t("compareForm.birthDate")}
                  isLight={isLight}
                  name="birthDate"
                  value={values.birthDate}
                  setFieldValue={setFieldValue}
                />
              </InputWrapper>
              <InputWrapper>
                <DoctorModelSelect
                  isLight={isLight}
                  name="doctorModel"
                  value={values.doctorModel}
                  onChange={(newVal) => setFieldValue("doctorModel", newVal)}
                />
              </InputWrapper>
              <InputWrapper>
                <Input
                  label={t("compareForm.phone")}
                  placeholder={t("compareForm.phone")}
                  isLight={isLight}
                  name="phoneNumber"
                  onChange={handleChange}
                  value={values.phoneNumber}
                  error={getErrorMessage(errors.phoneNumber)}
                />
              </InputWrapper>
              <InputWrapper>
                <Input
                  label={t("compareForm.email")}
                  placeholder={t("compareForm.email")}
                  isLight={isLight}
                  name="email"
                  onChange={handleChange}
                  value={values.email}
                  error={getErrorMessage(errors.email)}
                />
              </InputWrapper>
            </FormContainer>
            <AgreementCheckbox
              isLight={isLight}
              name="isAgreed"
              isAgreed={values.isAgreed}
              setIsAgreed={setFieldValue}
            />
            <ButtonWrapper>
              <Button
                text={t("send")}
                type="submit"
                isDisabled={!(isValid && dirty)}
              />
            </ButtonWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

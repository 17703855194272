import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Checkbox } from "../Checkbox";

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const Text = styled.p`
  margin: 0 0 0 10px;
  color: ${({ theme, isLight }) => (isLight ? "#FFF" : theme.colors.text)};
  font-size: 18px;
  flex: 1;

  @media all and (max-width: 780px) {
    font-size: 16px;
  }
`;

export const AgreementCheckbox = ({
  isLight,
  isAgreed,
  setIsAgreed,
  name,
}) => {
  const { t } = useTranslation();

  const onCheckboxClick = () => {
    setIsAgreed(name, !isAgreed);
  };

  return (
    <Container>
      <Checkbox isSelected={isAgreed} onClick={onCheckboxClick} />
      <Text isLight={isLight}>{t("compareForm.agreementText")}</Text>
    </Container>
  );
};

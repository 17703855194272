import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Description } from "../../../components/Description";
import { Section } from "../../../components/Section";
import { Title } from "../../../components/Title";

const companyLogos1 = [
  {
    url: "./images/aquilana.png",
    name: "Aquilana",
  },
  {
    url: "./images/Swica.png",
    name: "Swica",
  },
  {
    url: "./images/KPT.png",
    name: "KPT",
  },
];

const companyLogos2 = [
  {
    url: "./images/Visana.png",
    name: "Visana",
  },
  {
    url: "./images/Helsana.png",
    name: "Helsana",
  },
  {
    url: "./images/Sanitas.png",
    name: "Sanitas",
  },
];

const CompanyLogosWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media all and (max-width: 780px) {
    flex-direction: column;
    width: 100%;
  }
`;

const CompanyLogos = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 50px;
  flex: 1;

  @media all and (max-width: 780px) {
    flex: 0 1 auto;
    width: 100%;
    margin-top: 20px;
  }
`;

const Logo = styled.img`
  width: 100px;

  @media all and (max-width: 780px) {
    width: 80px;
  }
`;

export const InsuranceCompanies = () => {
  const { t } = useTranslation();
  return (
    <Section>
      <Title title={t("homePage.insuranceCompaniesTitle")} />
      <Description isCentered>
        <p>{t("homePage.insuranceCompaniesDescription")} </p>
      </Description>
      <CompanyLogosWrapper>
        <CompanyLogos>
          {companyLogos1.map((logo) => (
            <Logo key={logo.name} src={logo.url} alt={logo.name} />
          ))}
        </CompanyLogos>
        <CompanyLogos>
          {companyLogos2.map((logo) => (
            <Logo key={logo.name} src={logo.url} alt={logo.name} />
          ))}
        </CompanyLogos>
      </CompanyLogosWrapper>
    </Section>
  );
};

import { useTranslation } from "react-i18next";
import { Description } from "../../../components/Description";
import { Section } from "../../../components/Section";
import { Title } from "../../../components/Title";

export const PremiumIncrease = () => {
  const { t } = useTranslation();
  return (
    <Section>
      <Title title={t("homePage.premiumIncrease.title")} />
      <Description isCentered>
        <p>{t("homePage.premiumIncrease.description1")}</p>
      </Description>
      <Description isCentered>
        <p>{t("homePage.premiumIncrease.description2")}</p>
      </Description>
    </Section>
  );
};

import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Button } from "../../../components/Button";
import { CompareForm } from "../../../components/CompareForm/CompareForm";
import { CurrentInsuranceSelect } from "../../../components/CurrentInsuranceSelect/CurrentInsuranceSelect";
import { Modal } from "../../../components/Modal";
import { useCurrentInsurance } from "../../../providers/CurrentInsuranceProvider";
import { useTranslation } from "react-i18next";
import { SuccessMessageContent } from "../../../components/SuccessMessageContent";

const Container = styled.div`
  display: flex;
  align-items: center;

  @media all and (max-width: 593px) {
    flex-direction: column;
    width: 100%;
    align-items: normal;
  }
`;

const ButtonWrapper = styled.div`
  margin-left: 10px;

  @media all and (max-width: 593px) {
    margin-left: 0;
    margin-top: 20px;
  }
`;

const SelectWrapper = styled.div`
  flex: 1;
`;

export const CurrentHealthInsuranceSelect = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { setCurrentInsurance, currentInsurance } = useCurrentInsurance();
  const { t } = useTranslation();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const onCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setShowSuccessMessage(false)
  }, []);

  const onOpenModal = useCallback(() => {
    if (!currentInsurance) {
      toast.error("Choose insurance first");
      return;
    }

    setIsModalOpen(true);
  }, [currentInsurance]);

  return (
    <Container>
      <SelectWrapper>
        <CurrentInsuranceSelect
          onChange={(newVal) => setCurrentInsurance(newVal)}
        />
      </SelectWrapper>
      <ButtonWrapper>
        <Button text={t("continue")} onClick={onOpenModal} />
      </ButtonWrapper>
      <Modal isOpen={isModalOpen} closeModal={onCloseModal}>
        {showSuccessMessage ? (
          <SuccessMessageContent onClose={onCloseModal} />
        ) : (
          <CompareForm
            onCloseModal={onCloseModal}
            setShowSuccessMessage={setShowSuccessMessage}
          />
        )}
      </Modal>
    </Container>
  );
};

import { useTranslation } from "react-i18next";
import { Description } from "../../components/Description";
import { Page } from "../../components/Page";
import { Section } from "../../components/Section";

import { PageTitle } from "../../components/PageTitle";
import { Title } from "../../components/Title";

export const FranchiseAndDeductible = () => {
  const { t } = useTranslation();
  return (
    <Page
      title={t("metaTags.franchiseDeductible.title")}
      description={t("metaTags.franchiseDeductible.description")}
    >
      <PageTitle
        title={t("franchiseDeductible.linkLabel")}
        bgImage="./images/franchise.jpg"
      />
      <Section>
        <Title title={t("franchiseDeductible.franchiseTitle")} />
        <Description>
          <p>{t("franchiseDeductible.franchiseDescription")}</p>
        </Description>
      </Section>
      <Section>
        <Title title={t("franchiseDeductible.deductibleTitle")} />
        <Description>
          <p>{t("franchiseDeductible.deductibleDescription")}</p>
        </Description>
      </Section>
    </Page>
  );
};

import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useIsActiveDropdown } from "../useIsActiveDropdown";

const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
`;

const DropdownLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  font-size: 18px;
  color: ${({ theme, isOpen, isActiveDropdown }) =>
    isOpen || isActiveDropdown ? theme.colors.accent : theme.colors.text};
  font-weight: 600;
`;

const DropdownIconWrapper = styled.div`
  margin-left: 10px;
  transform: ${({ isOpen }) => (isOpen ? "rotateZ(-180deg) " : "none")};
  transition: all 0.4s ease;
  color: ${({ theme, isOpen, isActiveDropdown }) =>
    isOpen || isActiveDropdown ? theme.colors.accent : theme.colors.text};
`;

const DropdownMenu = styled.div`
  padding-top: 20px;
`;

const NavigationLink = styled(NavLink)`
  text-decoration: none;
  display: block;
  padding: 10px 10px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.grey}`};
`;

const NavigationLinkText = styled.div`
  font-size: 16px;
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.accent : theme.colors.text};
  font-weight: 600;
`;

export const MobileMenuDropdown = ({ label, items }) => {
  const [isOpen, setIsOpen] = useState(false);

  const isActiveDropdown = useIsActiveDropdown();

  const onToggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Dropdown>
      <DropdownLabelWrapper onClick={onToggleOpen}>
        <Label isOpen={isOpen} isActiveDropdown={isActiveDropdown}>
          {label}
        </Label>
        <DropdownIconWrapper
          isOpen={isOpen}
          isActiveDropdown={isActiveDropdown}
        >
          <FontAwesomeIcon icon={faChevronDown} />
        </DropdownIconWrapper>
      </DropdownLabelWrapper>
      {isOpen && (
        <DropdownMenu>
          {items.map((subItem) => (
            <NavigationLink key={subItem.label} to={subItem.link}>
              {({ isActive }) => {
                return (
                  <NavigationLinkText isActive={isActive}>
                    {subItem.label}
                  </NavigationLinkText>
                );
              }}
            </NavigationLink>
          ))}
        </DropdownMenu>
      )}
    </Dropdown>
  );
};

import { useTranslation } from "react-i18next";
import { Description } from "../../components/Description";
import { Page } from "../../components/Page";
import { Section } from "../../components/Section";

import { PageTitle } from "../../components/PageTitle";

export const ReservesDistribution = () => {
  const { t } = useTranslation();
  return (
    <Page
      title={t("metaTags.reserves.title")}
      description={t("metaTags.reserves.description")}
    >
      <PageTitle title={t("reserves.title")} bgImage="./images/reserves.jpg" />
      <Section>
        <Description>
          <p>{t("reserves.text1")}</p>
        </Description>
        <Description>
          <p>{t("reserves.text2")}</p>
        </Description>
        <Description>
          <p>{t("reserves.text3")}</p>
        </Description>
        <Description>
          <p>{t("reserves.text4")}</p>
        </Description>
        <Description>
          <p>{t("reserves.text5")}</p>
        </Description>
      </Section>
    </Page>
  );
};

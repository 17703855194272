import { useTranslation } from "react-i18next";
import { Description } from "../../components/Description";
import { Page } from "../../components/Page";
import { Section } from "../../components/Section";

import { PageTitle } from "../../components/PageTitle";

export const FamilyDiscounts = () => {
  const { t } = useTranslation();
  return (
    <Page
      title={t("metaTags.familyDiscounts.title")}
      description={t("metaTags.familyDiscounts.description")}
    >
      <PageTitle
        title={t("familyDiscounts.title")}
        bgImage="./images/family.jpg"
      />
      <Section>
        <Description>
          <p>{t("familyDiscounts.text1")} </p>
        </Description>
        <Description>
          <p>{t("familyDiscounts.text2")}</p>
        </Description>
        <Description>
          <p>{t("familyDiscounts.text3")}</p>
        </Description>
        <Description>
          <p>{t("familyDiscounts.text4")}</p>
        </Description>
      </Section>
    </Page>
  );
};

import { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useIsActiveDropdown } from "./useIsActiveDropdown";

const NavigationLink = styled(NavLink)`
  text-decoration: none;
  display: block;
  padding: 10px 20px;

  &:not(:last-of-type) {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.grey}`};
  }
`;

const NavigationDropdownContainer = styled.div`
  position: relative;
`;

const NavigationDropdownLabel = styled.div`
  font-size: 18px;
  color: ${({ theme, isOpen, isActiveDropdown }) =>
    isOpen || isActiveDropdown ? theme.colors.accent : theme.colors.text};
  font-weight: 600;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.accent};
  }

  @media all and (max-width: 1040px) {
    font-size: 16px;
  }
`;

const NavigationDropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: -50%;
  background-color: #fff;
  z-index: 3;
  white-space: nowrap;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding-top: 30px;
`;

const NavigationLinkText = styled.div`
  font-size: 16px;
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.accent : theme.colors.text};
  font-weight: 600;

  &:hover {
    color: ${({ theme }) => theme.colors.accent};
  }

  @media all and (max-width: 1040px) {
    font-size: 14px;
  }
`;

export const NavigationDropdown = ({ navLink }) => {
  const [isOpen, setIsOpen] = useState(false);

  const isActiveDropdown = useIsActiveDropdown();

  return (
    <NavigationDropdownContainer
      onMouseOver={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <NavigationDropdownLabel
        isOpen={isOpen}
        isActiveDropdown={isActiveDropdown}
      >
        {navLink.label}
      </NavigationDropdownLabel>
      {isOpen && (
        <NavigationDropdownMenu>
          {navLink.subItems.map((subItem) => (
            <NavigationLink to={subItem.link} key={subItem.label}>
              {({ isActive }) => {
                return (
                  <NavigationLinkText isActive={isActive}>
                    {subItem.label}
                  </NavigationLinkText>
                );
              }}
            </NavigationLink>
          ))}
        </NavigationDropdownMenu>
      )}
    </NavigationDropdownContainer>
  );
};

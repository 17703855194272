import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useIsMobile } from "../../hooks/useIsMobile";

const ItemContainer = styled.div`
  overflow: hidden;
  margin-bottom: 10px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  border: ${({ theme }) => `1px solid ${theme.colors.greyLight}`};
`;

const ItemTitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 20px;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.primary : "#fff"};
  border-bottom: ${({ theme, isActive }) =>
    isActive ? `1px solid ${theme.colors.greyLight}` : "none"};
  transition: all 0.4s ease;
  cursor: pointer;

  @media all and (max-width: 780px) {
    padding: 10px 8px;
  }
`;

const ArrowContainer = styled.div`
  transform: ${({ isActive }) => (isActive ? "rotateZ(90deg) " : "none")};
  transition: all 0.4s ease;
`;

const Arrow = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.accentLight};
  cursor: pointer;
`;

const AccordionTitle = styled.div`
  font-size: 18px;
  margin-left: 10px;
  color: ${({ theme, isActive }) => (isActive ? "#fff" : theme.colors.text)};
  font-weight: 600;
  letter-spacing: 1px;
  transition: all 0.4s ease;

  @media all and (max-width: 780px) {
    font-size: 14px;
  }
`;
const ItemDescriptionContainer = styled.div`
  height: ${({ isActive, contentHeight }) =>
    isActive ? `${contentHeight}px` : 0};
  transition: height 0.4s ease;
  background-color: ${({ theme }) => theme.colors.greyLight};
`;

const ItemDescription = styled.div`
  padding: 15px 20px;
  font-size: 18px;
  line-height: 1.6;
  color: ${({ theme }) => theme.colors.text};
  @media all and (max-width: 780px) {
    padding: 10px 8px;
    font-size: 14px;
    line-height: 1.4;
  }
`;

export const AccordionItem = ({
  isActive,
  accordionItem,
  onToggleAccordion,
}) => {
  const descriptionRef = useRef(null);
  const [descriptionH, setDescriptionH] = useState(0);

  const isMobile = useIsMobile();

  useEffect(() => {
    const descriptionHeight = descriptionRef.current?.clientHeight;
    if (descriptionHeight !== descriptionH) setDescriptionH(descriptionHeight);
  }, [descriptionH]);

  return (
    <ItemContainer>
      <ItemTitleContainer
        onClick={() => onToggleAccordion(accordionItem.title)}
        isActive={isActive}
      >
        <ArrowContainer isActive={isActive}>
          <Arrow icon={faChevronRight} size={isMobile ? "sm" : "lg"} />
        </ArrowContainer>
        <AccordionTitle isActive={isActive}>
          {accordionItem.title}
        </AccordionTitle>
      </ItemTitleContainer>
      <ItemDescriptionContainer
        contentHeight={descriptionH}
        isActive={isActive}
      >
        {/* <ItemDescriptionContent > */}
        <ItemDescription ref={descriptionRef}>
          {accordionItem.description}
        </ItemDescription>
        {/* </ItemDescriptionContent> */}
      </ItemDescriptionContainer>
    </ItemContainer>
  );
};

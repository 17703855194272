import styled from "styled-components";
import { colorOpacityApplier } from "../services/colorOpacityApplier";

const SectionContainer = styled.div`
  width: 100%;
  margin: ${({ noMargin }) => (noMargin ? 0 : "50px 0")};
  background: ${({ withBg, theme }) =>
    withBg
      ? colorOpacityApplier(theme.colors.primaryLight, "0.2")
      : "transparent"};

  @media all and (max-width: 780px) {
    margin: ${({ noMargin }) => (noMargin ? 0 : "20px 0")};
  }
`;

const SectionContainerInner = styled.div`
  width: ${({ isFullWidth }) => (isFullWidth ? "100%" : "95%")};
  margin: 0 auto;
  max-width: ${({ isFullWidth }) => (isFullWidth ? "100%" : "1280px")};

  padding: ${({ withBg }) => (withBg ? "50px 0" : 0)};

  @media all and (max-width: 780px) {
    padding: ${({ withBg }) => (withBg ? "20px 0" : 0)};
  }
`;

export const Section = ({ children, isFullWidth, withBg, noMargin }) => {
  return (
    <SectionContainer withBg={withBg} noMargin={noMargin}>
      <SectionContainerInner isFullWidth={isFullWidth} withBg={withBg}>
        {children}
      </SectionContainerInner>
    </SectionContainer>
  );
};

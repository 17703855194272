import Select from "react-select";
import styled, { useTheme } from "styled-components";
import { colorOpacityApplier } from "../services/colorOpacityApplier";
import { Label } from "./Label";

const getBackgroundOptionColor = (state, theme) => {
  switch (true) {
    case state.isSelected:
      return theme.colors.primaryLight;
    case state.isFocused:
      return colorOpacityApplier(theme.colors.primaryLight, "0.2");
    default:
      return "#fff";
  }
};

const getStyles = (theme) => {
  return {
    container: (provided, state) => ({
      ...provided,
      boxShadow: "2px 4px 12px rgba(0, 0, 0, 0.05)",
    }),
    control: (provided, state) => ({
      ...provided,
      fontFamily: theme.text.font.fontFamily,
      fontSize: 16,
      boxShadow: "none",
      color: theme.colors.text,
      border: `1px solid ${theme.colors.greyLight}`,
      ":hover": {
        border: `1px solid ${theme.colors.greyLight}`,
      },
    }),
    option: (provided, state) => {
      return {
        ...provided,
        fontFamily: theme.text.font.fontFamily,
        fontSize: 14,
        backgroundColor: getBackgroundOptionColor(state, theme),
        opacity: state.isDisabled ? 0.5 : 1,
        ":active": {
          backgroundColor: getBackgroundOptionColor(state, theme),
        },
      };
    },
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: theme.colors.grey,
        fontSize: "16px",
        fontStyle: "italic",
      };
    },
  };
};

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SelectDropdown = ({
  options,
  placeholder,
  label,
  isLight,
  name,
  value,
  onChange,
}) => {
  const theme = useTheme();

  return (
    <SelectContainer>
      {label && <Label label={label} isLight={isLight} />}
      <Select
        placeholder={placeholder}
        options={options}
        styles={getStyles(theme)}
        name={name}
        value={value}
        onChange={(newVal) => onChange(newVal)}
      />
    </SelectContainer>
  );
};

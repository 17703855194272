import { useTranslation } from "react-i18next";
import { SelectDropdown } from "../Select";

export const DoctorModelSelect = ({ isLight, name, value, onChange }) => {
  const { t } = useTranslation();

  const options = [
    {
      label: t("doctorModelOptions.familyDoctor"),
      value: t("doctorModelOptions.familyDoctor"),
    },
    {
      label: t("doctorModelOptions.hmo"),
      value: t("doctorModelOptions.hmo"),
    },
    {
      label: t("doctorModelOptions.telmed"),
      value: t("doctorModelOptions.telmed"),
    },
    {
      label: t("doctorModelOptions.freeChoiceOfDoc"),
      value: t("doctorModelOptions.freeChoiceOfDoc"),
    },
  ];

  return (
    <SelectDropdown
      options={options}
      label={t("compareForm.doctorModel")}
      placeholder={t("compareForm.doctorModel")}
      isLight={isLight}
      name={name}
      value={value}
      onChange={onChange}
    />
  );
};

import { Page } from "../../components/Page";
import { Section } from "../../components/Section";
import { useTranslation } from "react-i18next";
import { Title } from "../../components/Title";
import { CompareInsuranceSection } from "./CompareInsuranceSection";
import { Toast } from "../../components/Toast";

export const FormPage = () => {
  const { t } = useTranslation();
  return (
    <Page
      title={t("metaTags.formPage.title")}
      description={t("metaTags.home.description")}
    >
      <Section>
        <Title title={t("compareForm.title")} />
        <CompareInsuranceSection />
      </Section>
      <Toast />
    </Page>
  );
};

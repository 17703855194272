import { Routes, Route } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { AboutUs } from "./AboutUs/AboutUs";
import { DeathInsurance } from "./DeathInsurance/DeathInsurance";
import { DentalInsurance } from "./DentalInsurance/DentalInsurance";
import { FamilyDiscounts } from "./FamilyDiscounts/FamilyDiscounts";
import { FormPage } from "./FormPage/FormPage";
import { FranchiseAndDeductible } from "./FranchiseAndDeductible/FranchiseAndDeductible";
import { GuestInsurance } from "./GuestInsurance/GuestInsurance";
import { HomePage } from "./HomePage/HomePage";
import { HospitalInsurance } from "./HospitalInsurance/HospitalInsurance";
import { InsuranceModel } from "./InsuranceModel/InsuranceModel";
import { MaternityAndPrenatal } from "./MaternityAndPrenatal/MaternityAndPrenatal";
import { PremiumReduction } from "./PremiumReduction/PremiumReduction";
import { ReservesDistribution } from "./ReservesDistribution/ReservesDistribution";
import { TravelInsurance } from "./TravelInsurance/TravelInsurance";
import { BasicAndAdditionalInsurance } from "./BasicAndAdditionalInsurance/BasicAndAdditionalInsurance";
import { Impressum } from "./Impressum/Impressum";

export const Router = () => {
  return (
    <Routes>
      <Route path={ROUTES.INSURANCE_COMPARISON} element={<FormPage />} />
      <Route path={ROUTES.ABOUT_US} element={<AboutUs />} />
      <Route
        path={ROUTES.FRANCHISE_DEDUCTIBLE}
        element={<FranchiseAndDeductible />}
      />
      <Route path={ROUTES.FAMILY_DISCOUNTS} element={<FamilyDiscounts />} />
      <Route path={ROUTES.INSURANCE_MODEL} element={<InsuranceModel />} />
      <Route
        path={ROUTES.MATERNITY_PRENATAL}
        element={<MaternityAndPrenatal />}
      />
      <Route path={ROUTES.RESERVES} element={<ReservesDistribution />} />
      <Route path={ROUTES.GUEST_INSURANCE} element={<GuestInsurance />} />
      <Route path={ROUTES.TRAVEL_INSURANCE} element={<TravelInsurance />} />
      <Route path={ROUTES.PREMIUM_REDUCTION} element={<PremiumReduction />} />
      <Route path={ROUTES.DENTAL_INSURANCE} element={<DentalInsurance />} />
      <Route path={ROUTES.HOSPITAL_INSURANCE} element={<HospitalInsurance />} />
      <Route path={ROUTES.DEATH_INSURANCE} element={<DeathInsurance />} />
      <Route
        path={ROUTES.BASIC_ADDITIONAL_INSURANCE}
        element={<BasicAndAdditionalInsurance />}
      />
      <Route path={ROUTES.IMPRESSUM} element={<Impressum />} />
      <Route path="/" element={<HomePage />} />
    </Routes>
  );
};

import { useState } from "react";
import styled from "styled-components";
import { AccordionItem } from "./AccordionItem";

const Container = styled.div``;

export const Accordion = ({ accordionItems }) => {
  const [open, setOpen] = useState(null);

  const onToggleAccordion = (id) => {
    if (open === id) {
      setOpen(null);
      return;
    }
    setOpen(id);
  };

  return (
    <Container>
      {accordionItems.map((accordionItem) => (
        <AccordionItem
          key={accordionItem.title}
          accordionItem={accordionItem}
          isActive={open === accordionItem.title}
          onToggleAccordion={onToggleAccordion}
        />
      ))}
    </Container>
  );
};

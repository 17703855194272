import { storageService } from "./storageService";

class LanguageService {
  constructor() {
    this.languageList = ["de", "en"];
    this.defaultLanguage = "de";
  }

  setActiveLanguage(language) {
    storageService.set("lang", language);
    this.activeLanguage = storageService.get("lang");
  }

  getActiveLanguage() {
    const activeLanguage = storageService.get("lang");

    return activeLanguage || this.defaultLanguage;
  }
}

export const languageService = new LanguageService();

import { Section } from "../../../components/Section";
import styled from "styled-components";
import { CurrentHealthInsuranceSelect } from "./CurrentHealthInsuranceSelect";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "../../../hooks/useIsMobile";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media all and (max-width: 930px) {
    flex-direction: column;
  }
`;

const ImageWrapper = styled.div`
  width: 70%;
  margin-right: -10%;
  z-index: 1;
  border: 10px solid #fff;
  display: flex;

  @media all and (max-width: 930px) {
    width: 100%;
    margin-right: 0;
    border: none;
  }
`;

const MainImage = styled.img`
  width: 100%;
  height: 100%;
`;

const RightSide = styled.div`
  background-color: ${({ theme }) => theme.colors.bgColorLight};
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 600px;

  @media all and (max-width: 1260px) {
    height: 500px;
  }

  @media all and (max-width: 1040px) {
    height: 400px;
  }

  @media all and (max-width: 930px) {
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: flex-start;
    background-color: #fff;
  }
`;

const MainTextBox = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  margin-left: -10%;
  border: 10px solid #fff;
  padding: 20px 15px;
  width: 80%;
  z-index: 2;

  @media all and (max-width: 930px) {
    width: 70%;
    margin-left: 0;
    margin-top: -10%;
  }

  @media all and (max-width: 593px) {
    width: 80%;
    padding: 20px 10px;
  }
`;

const MainTextTitle = styled.h1`
  color: ${({ theme }) => theme.colors.greyLight};
  font-size: 32px;
  margin: 0;
  margin-bottom: 20px;

  @media all and (max-width: 1260px) {
    font-size: 30px;
  }

  @media all and (max-width: 1040px) {
    font-size: 22px;
  }

  @media all and (max-width: 780px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

const MainTextDescription = styled.p`
  color: ${({ theme }) => theme.colors.greyLight};
  font-size: 22px;
  margin: 0;
  margin-bottom: 8px;

  @media all and (max-width: 1260px) {
    font-size: 20px;
  }

  @media all and (max-width: 1040px) {
    font-size: 16px;
  }

  @media all and (max-width: 780px) {
    font-size: 14px;
    margin-bottom: 5px;
  }
`;

const MainTextBullet = styled.div`
  margin-right: 5px;
  color: ${({ theme }) => theme.colors.accentLight};
`;

const MainTextWrapper = styled.div`
  display: flex;
`;

export const Main = () => {
  const { t } = useTranslation();

  const isMobile = useIsMobile();

  const descriptionItems = [
    t("homePage.mainDescription1"),
    t("homePage.mainDescription2"),
    t("homePage.mainDescription3"),
    t("homePage.mainDescription4"),
  ];

  return (
    <Section>
      <Container>
        <ImageWrapper
          className={isMobile ? "" : "animate__animated animate__slideInLeft"}
        >
          <MainImage
            src={isMobile ? "./images/main-mobile.jpeg" : "./images/main.jpeg"}
            alt={t("family")}
          />
        </ImageWrapper>
        <RightSide>
          <MainTextBox
            className={
              isMobile ? "" : "animate__animated animate__slideInRight"
            }
          >
            <MainTextTitle>{t("homePage.mainTitle")}</MainTextTitle>
            {descriptionItems.map((descriptionItem) => (
              <MainTextWrapper key={descriptionItem}>
                <MainTextBullet>•</MainTextBullet>
                <MainTextDescription>{descriptionItem}</MainTextDescription>
              </MainTextWrapper>
            ))}
            <CurrentHealthInsuranceSelect />
          </MainTextBox>
        </RightSide>
      </Container>
    </Section>
  );
};

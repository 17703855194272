import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const Container = styled.div`
  border-radius: 6px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 20px 15px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:not(:last-child) {
    margin-right: 20px;
  }

  @media all and (max-width: 1040px) {
    padding: 15px 8px;

    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  @media all and (max-width: 780px) {
    flex: 1;
    width: auto;

    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
`;

const QuoteIconWrapper = styled.span`
  margin-left: ${({ isLeft }) => (isLeft ? 0 : "5px")};
  margin-right: ${({ isLeft }) => (isLeft ? "5px" : 0)};
`;

const TestimonialQuoteIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.primary};
`;

const TestimonialsText = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.text};

  @media all and (max-width: 1040px) {
    font-size: 16px;
  }
`;

const TestimonialName = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 700;
  font-style: italic;
  margin-top: 10px;
  text-align: center;

  @media all and (max-width: 1040px) {
    font-size: 14px;
  }
`;

export const TestimonialCard = ({ testimonial }) => {
  return (
    <Container>
      <TestimonialsText>
        <QuoteIconWrapper isLeft>
          <TestimonialQuoteIcon icon={faQuoteLeft} />
        </QuoteIconWrapper>
        {testimonial.text}{" "}
        <QuoteIconWrapper>
          <TestimonialQuoteIcon icon={faQuoteRight} />
        </QuoteIconWrapper>
      </TestimonialsText>
      <TestimonialName>{testimonial.name}</TestimonialName>
    </Container>
  );
};

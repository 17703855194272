import styled from "styled-components";

const StyledButton = styled.button`
  padding: 10px 30px;
  background-color: ${({ theme, isDisabled }) =>
    isDisabled ? theme.colors.grey : theme.colors.accentLight};
  border-radius: 6px;
  border: none;
  cursor: ${({ isDisabled }) => (isDisabled ? "auto" : "pointer")};
  color: ${({ theme }) => theme.colors.text};
  font-size: 16px;
  font-weight: 500;
  :hover {
    background-color: ${({ theme, isDisabled }) =>
      isDisabled ? theme.colors.grey : "#ffd98a"};
  }

  @media all and (max-width: 1040px) {
    font-size: 14px;
    padding: 8px 23px;
  }
`;

export const Button = ({ text, onClick, type = "button", isDisabled }) => {
  const onButtonClick = () => {
    if (isDisabled || !onClick) return;
    onClick();
  };

  return (
    <StyledButton onClick={onButtonClick} type={type} isDisabled={isDisabled}>
      {text}
    </StyledButton>
  );
};

import { useTranslation } from "react-i18next";
import { Page } from "../../components/Page";
import { Toast } from "../../components/Toast";
import { FAQSection } from "./FAQSection/FAQSection";
import { InsuranceCompanies } from "./InsuranceCompanies/InsuranceCompanies";
import { Main } from "./Main/Main";
import { PremiumIncrease } from "./PremiumIncrease/PremiumIncrease";
import { Testimonials } from "./Testimonials/Testimonials";

export const HomePage = () => {
  const { t } = useTranslation();
  return (
    <Page
      title={t("metaTags.home.title")}
      description={t("metaTags.home.description")}
    >
      <Main />
      <PremiumIncrease />
      <FAQSection />
      <InsuranceCompanies />
      <Testimonials />
      <Toast />
    </Page>
  );
};

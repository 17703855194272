import styled from "styled-components";

const DescriptionText = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.text};
  text-align: ${({ isCentered }) => (isCentered ? "center" : "justify")};
  line-height: 1.8;
  margin: 0;

  p {
    margin: 0;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding-left: 0;
  }

  li:before {
    content: "•";
    color: red;
    margin-right: 10px;
  }

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }

  @media all and (max-width: 780px) {
    font-size: 14px;
  }
`;

export const Description = ({ children, isCentered }) => {
  return <DescriptionText isCentered={isCentered}>{children}</DescriptionText>;
};

import styled from "styled-components";
import { languageService } from "../../../services/languageService";

const LanguagesContainer = styled.div`
  display: flex;
`;

const Language = styled.div`
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.accent : theme.colors.text};
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;

  @media all and (max-width: 1040px) {
    font-size: 14px;
  }
`;

const Separator = styled.div`
  color: ${({ theme }) => theme.colors.text};
  margin: 0 10px;

  @media all and (max-width: 1040px) {
    margin: 0 5px;
  }
`;

export const Languages = () => {
  const activeLanguage = languageService.getActiveLanguage();

  const onSetActiveLng = (lng) => {
    languageService.setActiveLanguage(lng);
    window.location.reload();
  };

  return (
    <LanguagesContainer>
      <Language
        isActive={activeLanguage === "de"}
        onClick={() => onSetActiveLng("de")}
      >
        de
      </Language>
      <Separator>/</Separator>
      <Language
        isActive={activeLanguage === "en"}
        onClick={() => onSetActiveLng("en")}
      >
        en
      </Language>
    </LanguagesContainer>
  );
};

import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ReactModal from "react-modal";
import styled, { useTheme } from "styled-components";
import { colorOpacityApplier } from "../services/colorOpacityApplier";

ReactModal.setAppElement("#modal");

const ModalComponentWrapped = styled(ReactModal)`
  background: #fff;
  width: 80%;
  margin: auto auto;
  padding: 0;
  z-index: 999;
  padding: 20px 40px;

  border-radius: 10px;
  @media all and (max-width: 780px) {
    max-height: 90%;
    top: 15px;
    bottom: 50px;
    right: 15px;
    left: 15px;
    padding: 8px 15px;
  }
`;

const CloseIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

const CloseIcon = styled(FontAwesomeIcon)`
  font-size: 30px;
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
`;

export const Modal = ({ children, isOpen, closeModal, contentStyle }) => {
  const theme = useTheme();
  const customStyles = {
    content: {
      overflowY: "auto",
      outline: "none",
      ...contentStyle,
    },
    overlay: {
      backgroundColor: colorOpacityApplier(theme.colors.text, "0.8"),
      zIndex: 99999,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
  return (
    <ModalComponentWrapped
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
    >
      <CloseIconContainer onClick={closeModal}>
        <CloseIcon icon={faClose} size={"2xl"} />
      </CloseIconContainer>
      <div>{children}</div>
    </ModalComponentWrapped>
  );
};

import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import styled from "styled-components";

import { Menu } from "./Menu";

const MenuIconWrapper = styled.div`
  position: relative;
  display: none;

  @media all and (max-width: 780px) {
    display: block;
  }
`;

const MenuIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`;

export const MobileMenu = () => {
  const [open, setOpen] = useState(null);

  return (
    <MenuIconWrapper>
      <MenuIcon icon={faBars} size={"2x"} onClick={() => setOpen(true)} />
      {open && <Menu setOpen={setOpen} />}
    </MenuIconWrapper>
  );
};

import { useTranslation } from "react-i18next";
import { Description } from "../../components/Description";
import { Page } from "../../components/Page";
import { Section } from "../../components/Section";

import { PageTitle } from "../../components/PageTitle";

export const GuestInsurance = () => {
  const { t } = useTranslation();
  return (
    <Page
      title={t("metaTags.guestInsurance.title")}
      description={t("metaTags.guestInsurance.description")}
    >
      <PageTitle
        title={t("guestInsurance.title")}
        bgImage="./images/guest.jpg"
      />
      <Section>
        <Description>
          <p>{t("guestInsurance.text1")}</p>
        </Description>
        <Description>
          <p>{t("guestInsurance.text2")}</p>
        </Description>
        <Description>
          <p>{t("guestInsurance.text3")}</p>
        </Description>
        <Description>
          <p>{t("guestInsurance.text4")}</p>
        </Description>
      </Section>
    </Page>
  );
};

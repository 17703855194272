const text = {
  font: {
    fontFamily: '"Open Sans"',
  },
  weight: {
    light: 200,
    regular: 400,
    bold: 700,
  },
};

export default text;

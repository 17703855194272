import { useTranslation } from "react-i18next";
import { useHealthInsuranceOptions } from "./useHealthInsuranceOptions";
import { SelectDropdown } from "../Select";
import { useCurrentInsurance } from "../../providers/CurrentInsuranceProvider";
import { useEffect } from "react";

export const CurrentInsuranceSelect = ({
  isLight,
  hasLabel,
  name,
  value,
  onChange,
}) => {
  const currentInsuranceOptions = useHealthInsuranceOptions();
  const { t } = useTranslation();

  const { currentInsurance } = useCurrentInsurance();

  useEffect(() => {
    if (currentInsurance) {
      onChange(currentInsurance);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SelectDropdown
      options={currentInsuranceOptions}
      placeholder={t("currentHealthInsuranceSelect")}
      label={hasLabel && t("currentHealthInsuranceSelect")}
      isLight={isLight}
      name={name}
      value={value || currentInsurance}
      onChange={onChange}
    />
  );
};

import styled from "styled-components";
import { colorOpacityApplier } from "../services/colorOpacityApplier";
import { Section } from "./Section";
import { Title } from "./Title";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 29%;
  position: relative;

  @media all and (max-width: 1280px) {
    height: 380px;
    padding-top: 0;
  }

  @media all and (max-width: 1040px) {
    height: 250px;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${({ bgImage }) => `url(${bgImage})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @media all and (max-width: 780px) {
    align-items: center;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: ${({ theme }) => `linear-gradient(
    to bottom,
    ${colorOpacityApplier(theme.colors.greyLight, "0.2")},
    ${colorOpacityApplier(theme.colors.primary, "0.9")}
  )`};
`;

export const PageTitle = ({ title, bgImage }) => {
  return (
    <Wrapper>
      <Container bgImage={bgImage}>
        <Overlay />
        <Section>
          <Title title={title} type="page" />
        </Section>
      </Container>
    </Wrapper>
  );
};

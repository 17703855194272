import { useTranslation } from "react-i18next";

export const useHealthInsuranceOptions = () => {
  const { t } = useTranslation();

  return [
    {
      label: t("insuranceOptions.newborn"),
      value: t("insuranceOptions.newborn"),
    },
    {
      label: t("insuranceOptions.newInSwitzerland"),
      value: t("insuranceOptions.newInSwitzerland"),
    },
    {
      label: t("insuranceOptions.amb"),
      value: t("insuranceOptions.amb"),
    },
    {
      label: t("insuranceOptions.agrisano"),
      value: t("insuranceOptions.agrisano"),
    },
    {
      label: t("insuranceOptions.aquilana"),
      value: t("insuranceOptions.aquilana"),
    },
    {
      label: t("insuranceOptions.arcosana"),
      value: t("insuranceOptions.arcosana"),
    },
    {
      label: t("insuranceOptions.assura"),
      value: t("insuranceOptions.assura"),
    },
    {
      label: t("insuranceOptions.atupri"),
      value: t("insuranceOptions.atupri"),
    },
    {
      label: t("insuranceOptions.avenir"),
      value: t("insuranceOptions.avenir"),
    },
    {
      label: t("insuranceOptions.birchmeier"),
      value: t("insuranceOptions.birchmeier"),
    },
    {
      label: t("insuranceOptions.concordia"),
      value: t("insuranceOptions.concordia"),
    },
    {
      label: t("insuranceOptions.css"),
      value: t("insuranceOptions.css"),
    },
    {
      label: t("insuranceOptions.caisseMaladie"),
      value: t("insuranceOptions.caisseMaladie"),
    },
    {
      label: t("insuranceOptions.compact"),
      value: t("insuranceOptions.compact"),
    },
    {
      label: t("insuranceOptions.egk"),
      value: t("insuranceOptions.egk"),
    },
    {
      label: t("insuranceOptions.easySana"),
      value: t("insuranceOptions.easySana"),
    },
    {
      label: t("insuranceOptions.einsiedler"),
      value: t("insuranceOptions.einsiedler"),
    },
    {
      label: t("insuranceOptions.galenos"),
      value: t("insuranceOptions.galenos"),
    },
    {
      label: t("insuranceOptions.glarner"),
      value: t("insuranceOptions.glarner"),
    },
    {
      label: t("insuranceOptions.steffisburg"),
      value: t("insuranceOptions.steffisburg"),
    },
    {
      label: t("insuranceOptions.hotela"),
      value: t("insuranceOptions.hotela"),
    },
    {
      label: t("insuranceOptions.helsana"),
      value: t("insuranceOptions.helsana"),
    },
    {
      label: t("insuranceOptions.hinterland"),
      value: t("insuranceOptions.hinterland"),
    },
    {
      label: t("insuranceOptions.ingenbohl"),
      value: t("insuranceOptions.ingenbohl"),
    },
    {
      label: t("insuranceOptions.intras"),
      value: t("insuranceOptions.intras"),
    },
    {
      label: t("insuranceOptions.klug"),
      value: t("insuranceOptions.klug"),
    },
    {
      label: t("insuranceOptions.kpt"),
      value: t("insuranceOptions.kpt"),
    },
    {
      label: t("insuranceOptions.kolping"),
      value: t("insuranceOptions.kolping"),
    },
    {
      label: t("insuranceOptions.lumneziana"),
      value: t("insuranceOptions.lumneziana"),
    },
    {
      label: t("insuranceOptions.Mitgliedgenossenschaften"),
      value: t("insuranceOptions.Mitgliedgenossenschaften"),
    },
    {
      label: t("insuranceOptions.mutuel"),
      value: t("insuranceOptions.mutuel"),
    },
    {
      label: t("insuranceOptions.mutuelleNeuchâteloise"),
      value: t("insuranceOptions.mutuelleNeuchâteloise"),
    },
    {
      label: t("insuranceOptions.provita"),
      value: t("insuranceOptions.provita"),
    },
    {
      label: t("insuranceOptions.progres"),
      value: t("insuranceOptions.progres"),
    },
    {
      label: t("insuranceOptions.Rhenusana"),
      value: t("insuranceOptions.Rhenusana"),
    },
    {
      label: t("insuranceOptions.slkk"),
      value: t("insuranceOptions.slkk"),
    },
    {
      label: t("insuranceOptions.supra"),
      value: t("insuranceOptions.supra"),
    },
    {
      label: t("insuranceOptions.swica"),
      value: t("insuranceOptions.swica"),
    },
    {
      label: t("insuranceOptions.sana24"),
      value: t("insuranceOptions.sana24"),
    },
    {
      label: t("insuranceOptions.Sanagate"),
      value: t("insuranceOptions.Sanagate"),
    },
    {
      label: t("insuranceOptions.sanavals"),
      value: t("insuranceOptions.sanavals"),
    },
    {
      label: t("insuranceOptions.sanitas"),
      value: t("insuranceOptions.sanitas"),
    },
    {
      label: t("insuranceOptions.simplon"),
      value: t("insuranceOptions.simplon"),
    },
    {
      label: t("insuranceOptions.sodalis"),
      value: t("insuranceOptions.sodalis"),
    },
    {
      label: t("insuranceOptions.stiftung"),
      value: t("insuranceOptions.stiftung"),
    },
    {
      label: t("insuranceOptions.sumiswalder"),
      value: t("insuranceOptions.sumiswalder"),
    },
    {
      label: t("insuranceOptions.sympany"),
      value: t("insuranceOptions.sympany"),
    },
    {
      label: t("insuranceOptions.visana"),
      value: t("insuranceOptions.visana"),
    },
    {
      label: t("insuranceOptions.vita"),
      value: t("insuranceOptions.vita"),
    },
    {
      label: t("insuranceOptions.vivacare"),
      value: t("insuranceOptions.vivacare"),
    },
    {
      label: t("insuranceOptions.vivao"),
      value: t("insuranceOptions.vivao"),
    },
    {
      label: t("insuranceOptions.okk"),
      value: t("insuranceOptions.okk"),
    },
  ];
};

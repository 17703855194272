import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Section } from "../../../components/Section";
import { Title } from "../../../components/Title";
import { TestimonialCard } from "./TestimonialCard";

const TestimonialsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media all and (max-width: 780px) {
    flex-direction: column;
  }
`;

export const Testimonials = () => {
  const { t } = useTranslation();

  const testimonialCards = [
    {
      name: t("homePage.testimonials.name1"),
      text: t("homePage.testimonials.testimonial1"),
    },
    {
      name: t("homePage.testimonials.name2"),
      text: t("homePage.testimonials.testimonial2"),
    },
    {
      name: t("homePage.testimonials.name3"),
      text: t("homePage.testimonials.testimonial3"),
    },
  ];

  return (
    <Section withBg noMargin>
      <Title title={t("homePage.testimonials.title")} />
      <TestimonialsContainer>
        {testimonialCards.map((testimonial) => {
          return (
            <TestimonialCard key={testimonial.name} testimonial={testimonial} />
          );
        })}
      </TestimonialsContainer>
    </Section>
  );
};

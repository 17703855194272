import { useTranslation } from "react-i18next";
import { ROUTES } from "../../../constants/routes";

export const useNavLinks = () => {
  const { t } = useTranslation();
  return [
    {
      link: "/",
      label: "Home",
    },
    {
      link: ROUTES.INSURANCE_COMPARISON,
      label: t("comparison"),
    },
    {
      link: ROUTES.ABOUT_US,
      label: t("aboutUs.title"),
    },
    {
      link: null,
      label: t("insuranceInformation"),
      subItems: [
        {
          link: ROUTES.BASIC_ADDITIONAL_INSURANCE,
          label: t("basicAndAdditional"),
        },
        {
          link: ROUTES.FRANCHISE_DEDUCTIBLE,
          label: t("franchiseDeductible.linkLabel"),
        },
        {
          link: ROUTES.INSURANCE_MODEL,
          label: t("insuranceModel.title"),
        },
        {
          link: ROUTES.HOSPITAL_INSURANCE,
          label: t("hospitalInsurance.title"),
        },
        {
          link: ROUTES.PREMIUM_REDUCTION,
          label: t("premiumReduction.title"),
        },
        {
          link: ROUTES.FAMILY_DISCOUNTS,
          label: t("familyDiscounts.title"),
        },

        {
          link: ROUTES.RESERVES,
          label: t("reserves.title"),
        },
        {
          link: ROUTES.GUEST_INSURANCE,
          label: t("guestInsurance.title"),
        },
        {
          link: ROUTES.TRAVEL_INSURANCE,
          label: t("travelInsurance.title"),
        },
        {
          link: ROUTES.MATERNITY_PRENATAL,
          label: t("maternityAndPrenatal.title"),
        },

        {
          link: ROUTES.DENTAL_INSURANCE,
          label: t("dentalInsurance.title"),
        },

        {
          link: ROUTES.DEATH_INSURANCE,
          label: t("deathInsurance.title"),
        },
      ],
    },
  ];
};

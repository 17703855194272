import { CompareForm } from "../../components/CompareForm/CompareForm";
import styled from "styled-components";
import { SuccessModal } from "./SuccessModal";
import { useState } from "react";

const FormContainer = styled.div`
  padding: 40px;
  background-color: ${({ theme }) => theme.colors.primaryLight};
  border-radius: 12px;

  @media all and (max-width: 780px) {
    padding: 20px;
  }
`;

export const CompareInsuranceSection = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <FormContainer>
      <CompareForm isLight setShowSuccessMessage={setShowModal} />
      <SuccessModal
        isOpen={showModal}
        onCloseModal={() => setShowModal(false)}
      />
    </FormContainer>
  );
};

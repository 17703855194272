/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled, { useTheme } from "styled-components";

const Container = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border-width: ${({ isSelected }) => (isSelected ? 0 : "1px")};
  border: ${({ theme }) => `1px solid ${theme.colors.grey}`};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFF;
  cursor: pointer;
`;

export const Checkbox = ({ isSelected, onClick }) => {
  const theme = useTheme();
  return (
    <Container isSelected={isSelected} onClick={onClick}>
      {isSelected && (
        <FontAwesomeIcon icon={faCheck} color={theme.colors.primaryLight} />
      )}
    </Container>
  );
};

import styled from "styled-components";
import { NavLink } from "react-router-dom";

const NavigationLink = styled(NavLink)`
  text-decoration: none;
`;

const NavigationLinkText = styled.div`
  font-size: 18px;
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.primaryLight : theme.colors.text};
  font-weight: 600;

  @media all and (max-width: 1040px) {
    font-size: 16px;
  }

  @media all and (max-width: 780px) {
    font-size: 18px;
  }
`;

export const NavBarLink = ({ label, link }) => {
  return (
    <NavigationLink to={link}>
      {({ isActive }) => {
        return (
          <NavigationLinkText isActive={isActive}>{label}</NavigationLinkText>
        );
      }}
    </NavigationLink>
  );
};

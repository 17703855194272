import { useTranslation } from "react-i18next";
import { Description } from "../../components/Description";
import { Page } from "../../components/Page";
import { Section } from "../../components/Section";

import { PageTitle } from "../../components/PageTitle";

export const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <Page
      title={t("metaTags.aboutUs.title")}
      description={t("metaTags.home.description")}
    >
      <PageTitle title={t("aboutUs.title")} bgImage="./images/insurance.jpg" />
      <Section>
        <Description>
          <p>{t("aboutUs.text1")}</p>
        </Description>
        <Description>
          <p>{t("aboutUs.text2")}</p>
        </Description>
        <Description>
          <p>{t("aboutUs.text3")} </p>
        </Description>
      </Section>
    </Page>
  );
};

import styled from "styled-components";
import { NavBarLink } from "./NavbarLink";
import { NavigationDropdown } from "./NavigationDropdown";
import { useNavLinks } from "./useNavLinks";

const HeaderNavStyled = styled.nav`
  display: flex;
  margin-right: 30px;

  @media all and (max-width: 1040px) {
    margin-right: 20px;
  }
`;

const NavLinkWrapper = styled.div`
  margin-right: 25px;

  @media all and (max-width: 1040px) {
    margin-right: 15px;
  }
`;

export const HeaderNav = () => {
  const navLinks = useNavLinks();
  return (
    <HeaderNavStyled>
      {navLinks.map((navLink) => (
        <NavLinkWrapper key={navLink.label}>
          {navLink.link && (
            <NavBarLink link={navLink.link} label={navLink.label} />
          )}
          {navLink.subItems && <NavigationDropdown navLink={navLink} />}
        </NavLinkWrapper>
      ))}
    </HeaderNavStyled>
  );
};

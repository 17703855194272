import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { useIsMobile } from "../hooks/useIsMobile";
import { Button } from "./Button";

export const SuccessMessageContent = ({ onClose }) => {
  const theme = useTheme();

  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <Container>
      <FontAwesomeIcon
        icon={faCheckCircle}
        color={theme.colors.primaryLight}
        size={isMobile ? "4x" : "6x"}
      />
      <Title>{t("successMessageTitle")}</Title>
      <Text>{t("successMessageText")}</Text>
      <Button text={"OK"} onClick={onClose} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
  @media all and (max-width: 780px) {
    padding: 10px 8px;
  }
`;

const Title = styled.div`
  font-size: 32px;
  margin-top: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  @media all and (max-width: 780px) {
    font-size: 20px;
    margin-top: 10px;
  }
`;

const Text = styled.div`
  font-size: 20px;
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;
  color: ${({ theme }) => theme.colors.text};
  @media all and (max-width: 780px) {
    font-size: 14px;
    margin-top: 20px;
  }
`;

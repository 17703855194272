import styled from "styled-components";

const getTitleAlignment = (type) => {
  switch (type) {
    case "section":
      return "center";
    case "page":
      return "start";
    default:
      return "center";
  }
};

const getFontSize = (type, baseSize) => {
  switch (type) {
    case "section":
      return baseSize;
    case "page":
      return baseSize + 10;
    default:
      return baseSize;
  }
};

const getColor = (type, theme) => {
  switch (type) {
    case "section":
      return theme.colors.primary;
    case "page":
      return "#fff";
    default:
      return theme.colors.primary;
  }
};

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ type }) => getTitleAlignment(type)};
  width: 100%;
  z-index: 1;
  position: relative;

  @media all and (max-width: 780px) {
    align-items: center;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media all and (max-width: 780px) {
    align-items: center;
  }
`;

const TitleStyled = styled.h3`
  font-weight: 700;
  margin: 0 0 5px 0;
  font-size: ${({ type }) => `${getFontSize(type, 36)}px`};
  color: ${({ type, theme }) => getColor(type, theme)};

  @media all and (max-width: 1040px) {
    font-size: ${({ type }) => `${getFontSize(type, 28)}px`};
  }

  @media all and (max-width: 780px) {
    font-size: ${({ type }) => `${getFontSize(type, 22)}px`};
    text-align: center;
    word-break: break-word;
  }

  @media all and (max-width: 475px) {
    font-size: ${({ type }) => type === 'section' ? `${getFontSize(type, 16)}px` : `${getFontSize(type, 12)}px`};
    text-align: center;
    word-break: break-word;
  }
`;

const Dash = styled.div`
  width: 100px;
  height: 3px;
  background-color: ${({ theme }) => theme.colors.accentLight};
  margin-bottom: 30px;

  @media all and (max-width: 780px) {
    margin-bottom: 15px;
  }
`;

export const Title = ({ title, type = "section" }) => {
  return (
    <TitleContainer type={type}>
      <TitleWrapper>
        <TitleStyled type={type}>{title}</TitleStyled>
        <Dash />
      </TitleWrapper>
    </TitleContainer>
  );
};

import { useTranslation } from "react-i18next";
import { Description } from "../../components/Description";
import { Page } from "../../components/Page";
import { Section } from "../../components/Section";

import { PageTitle } from "../../components/PageTitle";
import styled from "styled-components";

const BoldText = styled.span`
  font-weight: 600;
`;

export const InsuranceModel = () => {
  const { t } = useTranslation();
  return (
    <Page
      title={t("metaTags.insuranceModel.title")}
      description={t("metaTags.insuranceModel.description")}
    >
      <PageTitle
        title={t("insuranceModel.title")}
        bgImage="./images/insuranceModel.jpg"
      />
      <Section>
        <Description>
          <p>
            <BoldText>{t("insuranceModel.text1Bold")}</BoldText>{" "}
            {t("insuranceModel.text1")}
          </p>
        </Description>
        <Description>
          <p>
            <BoldText>{t("insuranceModel.text2Bold")}</BoldText>{" "}
            {t("insuranceModel.text2")}
          </p>
        </Description>
        <Description>
          <p>
            <BoldText>{t("insuranceModel.text3Bold")}</BoldText>{" "}
            {t("insuranceModel.text3")}
          </p>
        </Description>
        <Description>
          <p>
            <BoldText>{t("insuranceModel.text4Bold")}</BoldText>{" "}
            {t("insuranceModel.text4")}
          </p>
        </Description>
      </Section>
    </Page>
  );
};

import styled from "styled-components";
import { Label } from "./Label";

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledInput = styled.input`
  border: ${({ theme }) => `1px solid ${theme.colors.greyLight}`};
  background-color: "#FFF";
  border-radius: 4px;
  font-size: 16px;
  padding: 0px 8px;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.05);
  outline: none;
  height: 38px;
  ::placeholder {
    color: ${({ theme }) => theme.colors.grey};
    font-size: 16px;
    font-style: italic;
    font-family: Open sans;
  }
`;

const ErrorMsg = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.error};
  margin-top: 5px;
`;

export const Input = ({
  label,
  placeholder,
  isLight,
  value,
  onChange,
  name,
  error,
  type = "text",
}) => {
  return (
    <InputContainer>
      <Label label={label} isLight={isLight} />
      <StyledInput
        id={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        name={name}
        type={type}
        aria-label={label}
      />
      {error && <ErrorMsg>***{error}</ErrorMsg>}
    </InputContainer>
  );
};

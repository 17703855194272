import { useTranslation } from "react-i18next";
import { Accordion } from "../../../components/Accordion/Accordion";
import { Section } from "../../../components/Section";
import { Title } from "../../../components/Title";

export const FAQSection = () => {
  const { t } = useTranslation();

  const faqs = [
    {
      title: t("homePage.faq.question1"),
      description: t("homePage.faq.answer1"),
    },
    {
      title: t("homePage.faq.question2"),
      description: t("homePage.faq.answer2"),
    },
    {
      title: t("homePage.faq.question3"),
      description: t("homePage.faq.answer3"),
    },
    {
      title: t("homePage.faq.question4"),
      description: t("homePage.faq.answer4"),
    },
    {
      title: t("homePage.faq.question5"),
      description: t("homePage.faq.answer5"),
    },
  ];

  return (
    <Section withBg>
      <Title title={t("homePage.faq.title")} />
      <Accordion accordionItems={faqs} />
    </Section>
  );
};

import { colorOpacityApplier } from "../services/colorOpacityApplier";

const colors = {
  text: "#323031",
  primary: "#084C61",
  primaryLight: "#177E89",
  accentLight: "#FFC857",
  accent: "#e40524",
  greyLight: "#e7e7e7",
  grey: "#b3b3b3",
  bgColorLight: colorOpacityApplier("#177E89", "0.2"),
  error: "#B22222",
};

export default colors;

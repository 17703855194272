import { useTranslation } from "react-i18next";
import { Description } from "../../components/Description";
import { Page } from "../../components/Page";
import { Section } from "../../components/Section";

import { PageTitle } from "../../components/PageTitle";
import { Title } from "../../components/Title";

export const BasicAndAdditionalInsurance = () => {
  const { t } = useTranslation();
  return (
    <Page
      title={t("metaTags.basicAndAdditionalInsurance.title")}
      description={t("metaTags.basicAndAdditionalInsurance.description")}
    >
      <PageTitle
        title={t("basicAndAdditional")}
        bgImage="./images/basicInsurance.jpg"
      />
      <Section>
        <Title title={t("basicInsurance.title")} />
        <Description>
          <p>{t("basicInsurance.text")}</p>
        </Description>
      </Section>
      <Section>
        <Title title={t("additionalInsurance.title")} />
        <Description>
          <p>{t("additionalInsurance.text")}</p>
        </Description>
      </Section>
    </Page>
  );
};

import React, { useMemo, useState } from "react";

const CurrentInsuranceContext = React.createContext(undefined);

export const useCurrentInsurance = () => {
  const context = React.useContext(CurrentInsuranceContext);

  if (context === undefined) {
    throw new Error(
      "useCurrentInsurance cannot be used outside CurrentInsuranceProvider!"
    );
  }

  return context;
};

export const CurrentInsuranceProvider = ({ children }) => {
  const [currentInsurance, setCurrentInsurance] = useState();

  const value = useMemo(
    () => ({
      currentInsurance,
      setCurrentInsurance,
    }),
    [currentInsurance, setCurrentInsurance]
  );

  return (
    <CurrentInsuranceContext.Provider value={value}>
      {children}
    </CurrentInsuranceContext.Provider>
  );
};

import {
  faFacebookF,
  faInstagram,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Section } from "./Section";
import { ROUTES } from "../constants/routes";
import { NavLink } from "react-router-dom";

const FooterContainer = styled.div`
  border-top: ${({ theme }) => `3px solid ${theme.colors.primary}`};
`;

const FooterContent = styled.div`
  padding: 50px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media all and (max-width: 780px) {
    flex-direction: column;
  }
`;

const ColumnsContainer = styled.div`
  display: flex;
  width: 70%;
  justify-content: space-between;

  @media all and (max-width: 780px) {
    width: 100%;
  }

  @media all and (max-width: 540px) {
    flex-direction: column;
  }
`;

const Logo = styled.img`
  width: 230px;

  @media all and (max-width: 947px) {
    width: 180px;
  }

  @media all and (max-width: 780px) {
    margin-bottom: 20px;
  }

  @media all and (max-width: 540px) {
    width: 130px;
  }
`;

const ColumnWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media all and (max-width: 1080px) {
    align-items: flex-start;
    width: auto;
  }

  @media all and (max-width: 540px) {
    margin-bottom: 20px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const ColumnTitle = styled.div`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.text};
  font-weight: 700;
  text-transform: uppercase;

  @media all and (max-width: 540px) {
    font-size: 18px;
  }
`;

const ColumnItem = styled.div`
  margin-top: 15px;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.text};
  @media all and (max-width: 780px) {
    font-size: 14px;
  }
`;

const Link = styled(NavLink)`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.primaryLight};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.colors.accentLight};
  }

  @media all and (max-width: 540px) {
    font-size: 14px;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  margin-top: 15px;
`;

const SocialIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.primary};
  margin: 0 10px;
`;

const ImpressumLinkWrapper = styled.div`
  margin-bottom: 20px;
`;

export const Footer = () => {
  const { t } = useTranslation();

  const navLinks = [
    {
      link: ROUTES.BASIC_ADDITIONAL_INSURANCE,
      label: t("basicAndAdditional"),
    },
    {
      link: ROUTES.FRANCHISE_DEDUCTIBLE,
      label: t("franchiseDeductible.linkLabel"),
    },
    {
      link: ROUTES.INSURANCE_MODEL,
      label: t("insuranceModel.title"),
    },
    {
      link: ROUTES.PREMIUM_REDUCTION,
      label: t("premiumReduction.title"),
    },
  ];

  return (
    <FooterContainer>
      <Section noMargin>
        <FooterContent>
          <Logo src={"./images/logo.svg"} alt="KK-Schweiz" />
          <ColumnsContainer>
            <ColumnWrapper>
              <Column>
                <ColumnTitle>{t("footer.important")}</ColumnTitle>
                {navLinks.map((link) => (
                  <ColumnItem>
                    <Link to={link.link}>{link.label}</Link>
                  </ColumnItem>
                ))}
              </Column>
            </ColumnWrapper>
            <ColumnWrapper>
              <Column>
                <ImpressumLinkWrapper>
                  <ColumnTitle>{t("footer.legal")}</ColumnTitle>
                  <Link to={ROUTES.IMPRESSUM}>{t("footer.impressum")}</Link>
                </ImpressumLinkWrapper>
                <ColumnTitle>{t("footer.socialLinks")}</ColumnTitle>
                <SocialLinks>
                  <a href="/#">
                    <SocialIcon icon={faFacebookF} size={"lg"} />
                  </a>
                  <a href="/#">
                    <SocialIcon icon={faInstagram} size={"lg"} />
                  </a>
                  <a href="/#">
                    <SocialIcon icon={faTwitter} size={"lg"} />
                  </a>
                </SocialLinks>
              </Column>
            </ColumnWrapper>
          </ColumnsContainer>
        </FooterContent>
      </Section>
    </FooterContainer>
  );
};

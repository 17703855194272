import { useTranslation } from "react-i18next";
import { Description } from "../../components/Description";
import { Page } from "../../components/Page";
import { Section } from "../../components/Section";

import { PageTitle } from "../../components/PageTitle";

export const MaternityAndPrenatal = () => {
  const { t } = useTranslation();

  const listItems = [
    t("maternityAndPrenatal.item1"),
    t("maternityAndPrenatal.item2"),
    t("maternityAndPrenatal.item3"),
    t("maternityAndPrenatal.item4"),
    t("maternityAndPrenatal.item5"),
    t("maternityAndPrenatal.item6"),
    t("maternityAndPrenatal.item7"),
    t("maternityAndPrenatal.item8"),
  ];

  return (
    <Page
      title={t("metaTags.maternityAndPrenatal.title")}
      description={t("metaTags.maternityAndPrenatal.description")}
    >
      <PageTitle
        title={t("maternityAndPrenatal.title")}
        bgImage="./images/maternity.jpg"
      />
      <Section>
        <Description>
          <p>{t("maternityAndPrenatal.text1")}</p>
        </Description>
        <Description>
          <p>{t("maternityAndPrenatal.text2")}</p>
        </Description>
        <Description>
          <p>{t("maternityAndPrenatal.text3")}</p>
        </Description>
        <Description>
          <p>{t("maternityAndPrenatal.text4")}</p>
        </Description>
        <Description>
          <p>{t("maternityAndPrenatal.text5")}</p>
        </Description>
        <Description>
          <ul>
            {listItems.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </Description>
      </Section>
    </Page>
  );
};

import { useTranslation } from "react-i18next";
import { Description } from "../../components/Description";
import { Page } from "../../components/Page";
import { Section } from "../../components/Section";

import { PageTitle } from "../../components/PageTitle";
import styled from "styled-components";

const BoldText = styled.span`
  font-weight: 600;
`;

export const HospitalInsurance = () => {
  const { t } = useTranslation();
  return (
    <Page
      title={t("metaTags.hospitalInsurance.title")}
      description={t("metaTags.hospitalInsurance.description")}
    >
      <PageTitle
        title={t("hospitalInsurance.title")}
        bgImage="./images/hospital.jpg"
      />
      <Section>
        <Description>
          <p>{t("hospitalInsurance.text1")}</p>
        </Description>
        <Description>
          <p>
            {t("hospitalInsurance.text2Part1")}{" "}
            <BoldText>{t("hospitalInsurance.text2Part2")} </BoldText>
            {t("hospitalInsurance.text2Part3")}
          </p>
        </Description>
        <Description>
          <p>
            {t("hospitalInsurance.text3Part1")}{" "}
            <BoldText>{t("hospitalInsurance.text3Part2")} </BoldText>
            {t("hospitalInsurance.text3Part3")}
          </p>
        </Description>
        <Description>
          <p>
            {t("hospitalInsurance.text4Part1")}{" "}
            <BoldText>{t("hospitalInsurance.text4Part2")} </BoldText>
            {t("hospitalInsurance.text4Part3")}
          </p>
        </Description>
        <Description>
          <p>{t("hospitalInsurance.text5")}</p>
        </Description>
        <Description>
          <p>{t("hospitalInsurance.text6")}</p>
        </Description>
      </Section>
    </Page>
  );
};

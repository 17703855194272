import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";

export const useIsActiveDropdown = () => {
  const location = useLocation();

  const isActiveRoute = useMemo(() => {
    const navRoutes = [
      ROUTES.FRANCHISE_DEDUCTIBLE,
      ROUTES.FAMILY_DISCOUNTS,
      ROUTES.INSURANCE_MODEL,
      ROUTES.MATERNITY_PRENATAL,
      ROUTES.RESERVES,
      ROUTES.GUEST_INSURANCE,
      ROUTES.TRAVEL_INSURANCE,
      ROUTES.PREMIUM_REDUCTION,
      ROUTES.DENTAL_INSURANCE,
      ROUTES.HOSPITAL_INSURANCE,
      ROUTES.DEATH_INSURANCE,
    ];
    return navRoutes.includes(location.pathname);
  }, [location.pathname]);

  return isActiveRoute;
};

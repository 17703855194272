import styled from "styled-components";

import { HeaderMenu } from "./HeaderMenu/HeaderMenu";
import { NavLink } from "react-router-dom";

const SectionContainer = styled.div`
  width: 100%;
`;

const SectionContainerInner = styled.div`
  width: 90%;
  margin: 0 auto;
  max-width: 1280px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
`;

const Logo = styled.img`
  width: 130px;
`;

const RightSide = styled.div`
  display: flex;
  align-items: center;
`;

export const Header = () => {
  return (
    <SectionContainer>
      <SectionContainerInner>
        <HeaderContainer>
          <NavLink to="/">
            <Logo src={"./images/logo.svg"} alt="KK-Schweiz" />
          </NavLink>
          <RightSide>
            <HeaderMenu />
          </RightSide>
        </HeaderContainer>
      </SectionContainerInner>
    </SectionContainer>
  );
};

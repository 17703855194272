import { useTranslation } from "react-i18next";
import { Description } from "../../components/Description";
import { Page } from "../../components/Page";
import { Section } from "../../components/Section";

import { PageTitle } from "../../components/PageTitle";

export const TravelInsurance = () => {
  const { t } = useTranslation();
  const listItems = [
    t("travelInsurance.item1"),
    t("travelInsurance.item2"),
    t("travelInsurance.item3"),
    t("travelInsurance.item4"),
    t("travelInsurance.item5"),
  ];
  return (
    <Page
      title={t("metaTags.travelInsurance.title")}
      description={t("metaTags.travelInsurance.description")}
    >
      <PageTitle
        title={t("travelInsurance.title")}
        bgImage="./images/travel.jpg"
      />
      <Section>
        <Description>
          <p>{t("travelInsurance.text1")}</p>
        </Description>
        <Description>
          <ul>
            {listItems.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </Description>
        <Description>
          <p>{t("travelInsurance.text2")}</p>
        </Description>
      </Section>
    </Page>
  );
};

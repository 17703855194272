class StorageService {
  set(key, value) {
    try {
      return localStorage.setItem(key, JSON.stringify(value));
    } catch (err) {
      return null;
    }
  }

  get(key) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (err) {
      return null;
    }
  }
}

export const storageService = new StorageService();

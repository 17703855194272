import colors from "./colors";
import text from "./text";

const theme = {
  name: "app",
  colors,
  text,
};

export default theme;

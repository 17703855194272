import { Modal } from "../../components/Modal";
import { SuccessMessageContent } from "../../components/SuccessMessageContent";
import { useIsMobile } from "../../hooks/useIsMobile";

export const SuccessModal = ({ isOpen, onCloseModal }) => {
  const isMobile = useIsMobile();
  return (
    <Modal
      isOpen={isOpen}
      closeModal={onCloseModal}
      contentStyle={{ width: isMobile ? "270px" : "650px", overflow: "hidden" }}
    >
      <SuccessMessageContent onClose={onCloseModal} />
    </Modal>
  );
};

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationDE from "../locales/de.json";
import translationEN from "../locales/en.json";
import { languageService } from "../services/languageService";

const resources = {
  de: {
    translation: translationDE,
  },
  en: {
    translation: translationEN,
  },
};

i18n.use(initReactI18next).init({
  fallbackLng: "de",
  debug: true,
  resources,
  lng: languageService.getActiveLanguage()
    ? languageService.getActiveLanguage()
    : languageService.defaultLanguage,
  keySeparator: ".",
});

export default i18n;

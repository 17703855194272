import styled from "styled-components";
import { HeaderNav } from "./HeaderNav";
import { Languages } from "./Languages";
import { MobileMenu } from "./MobileMenu/MobileMenu";

const DesktopNav = styled.div`
  display: flex;

  @media all and (max-width: 780px) {
    display: none;
  }
`;

export const HeaderMenu = () => {
  return (
    <>
      <DesktopNav>
        <HeaderNav />
        <Languages />
      </DesktopNav>
      <MobileMenu />
    </>
  );
};

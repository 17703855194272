import { useTranslation } from "react-i18next";
import { Page } from "../../components/Page";
import { PageTitle } from "../../components/PageTitle";
import { Section } from "../../components/Section";
import { Title } from "../../components/Title";
import { Description } from "../../components/Description";
import styled from "styled-components";

const ContactWrapper = styled.div`
  margin-top: 20px;
`;

const Link = styled.a`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.primaryLight};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.colors.accentLight};
  }

  @media all and (max-width: 540px) {
    font-size: 14px;
  }
`;

const BoldText = styled.p`
  padding-top: 40px;
  font-weight: 700;
`;

export const Impressum = () => {
  const { t } = useTranslation();
  return (
    <Page>
      <PageTitle title={t("impressum.title")} bgImage="./images/legal.jpeg" />
      <Section>
        <Title title={t("impressum.section1.title")} />
        <Description>
          <p>{t("impressum.section1.description")}</p>
        </Description>
      </Section>
      <Section>
        <Title title={t("impressum.section2.title")} />
        <Description>
          <p>{t("impressum.section2.description")}</p>
        </Description>
      </Section>
      <Section>
        <Title title={t("impressum.section3.title")} />
        <Description>
          <p>{t("impressum.section3.description")}</p>
        </Description>
      </Section>
      <Section>
        <Title title={t("impressum.section4.title")} />
        <Description>
          <p>{t("impressum.section4.description")}</p>
        </Description>
      </Section>
      <Section>
        <Title title={t("impressum.section5.title")} />
        <Description>
          <p>{t("impressum.section5.description")}</p>
        </Description>
      </Section>
      <Section>
        <Title title={t("impressum.section6.title")} />
        <Description>
          <p>{t("impressum.section6.description")}</p>
        </Description>
      </Section>
      <Section>
        <Title title={t("impressum.section7.title")} />
        <Description>
          <p>{t("impressum.section7.description")}</p>
        </Description>
      </Section>
      <Section>
        <Title title={t("impressum.section8.title")} />
        <Description>
          <p>{t("impressum.section8.description")}</p>
        </Description>
      </Section>
      <Section>
        <Title title={t("impressum.section9.title")} />
        <Description>
          <p>{t("impressum.section9.description")}</p>
        </Description>
      </Section>
      <Section>
        <Title title={t("impressum.section10.title")} />
        <Description>
          <p>{t("impressum.section10.description")}</p>
        </Description>
      </Section>
      <Section>
        <Title title={t("impressum.section11.title")} />
        <Description>
          <p>{t("impressum.section11.description")}</p>
        </Description>
      </Section>
      <Section>
        <Title title={t("impressum.section12.title")} />
        <Description>
          <p>{t("impressum.section12.description")}</p>
        </Description>
      </Section>
      <Section>
        <Title title={t("impressum.section13.title")} />
        <Description>
          <p>{t("impressum.section13.description")}</p>
        </Description>
      </Section>
      <Section>
        <Title title={t("impressum.section14.title")} />
        <Description>
          <p>{t("impressum.section14.description")}</p>
          <ContactWrapper>
            <p>Hans Consulting GmbH</p>
            <p>Bruggerstrasse 194</p>
            <p>5400 Baden</p>
            <p>
              <Link href="mailto:Info@hans-consulting.ch">
                Info@hans-consulting.ch
              </Link>
            </p>
            <BoldText>{t("impressum.expDateText")}</BoldText>
          </ContactWrapper>
        </Description>
      </Section>
    </Page>
  );
};
